.App {
  text-align: center;
}

.nav {
  background-color: #2c3e50;
  padding: 10px 0;
}

.nav a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  font-size: 18px;
}

.nav a:hover {
  color: #3498db;
}

.chat-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-message {
  background-color: #f1f1f1;
  border-radius: 20px;
  padding: 10px 15px;
  margin-bottom: 10px;
  max-width: 70%;
}

.chat-message.user {
  background-color: #3498db;
  color: white;
  align-self: flex-end;
}

.container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.toaster-container {
  position: fixed;
  bottom: 20px;
  left: 20px; /* rightをleftに変更 */
  z-index: 1000;
}

.toast-item {
  background-color: #f8f9fa;
  border-left: 4px solid #007bff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 350px;
}

.toast-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

.toast-content {
  font-size: 14px;
  color: #555;
  line-height: 1.4;
}

.toast-content p {
  margin: 0;
  white-space: pre-wrap;
}

.toast-close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

/* 例: Tailwind CSSを使用している場合 */
.fixed {
  position: fixed;
}
.bottom-4 {
  bottom: 1rem;
}
.left-4 {
  left: 1rem;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: calc(100% - 16px);
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

button {
  width: calc(100% - 16px); /* ボタンの幅を調整 */
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-sizing: border-box; /* パディングを幅に含める */
}

button:hover {
  background-color: #2980b9;
}

#card-container {
  min-height: 100px;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
}

/* Mermaidダイアログのスタイル */
.mermaid-dialog-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.mermaid-dialog-content {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mermaid-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background-color: #f0f0f0;
}

.mermaid-dialog-title {
  font-size: 1rem;
  font-weight: bold;
}

.mermaid-dialog-controls {
  display: flex;
  align-items: center;
}

.mermaid-dialog-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;
  color: #333; /* ボタンの色を濃くする */
}

.mermaid-dialog-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000; /* ホバー時にさらに濃くする */
}

.mermaid-diagram-container {
  flex-grow: 1;
  overflow: auto;
  padding: 0.5rem;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
